<template>
  <v-container fluid>
    <v-form v-model="valid" :lazy-validation="false" class="ma-4">
      <v-text-field v-model="form.id" label="ID" readonly>
        <template slot="append">
          <v-icon title="Copy" class="mr-1" @click="_clipboard(form.id)">mdi-content-copy</v-icon>
        </template>
      </v-text-field>

      <v-text-field v-model="form.secretKey" label="Secret Key" readonly>
        <template slot="append">
          <v-icon title="Copy" class="mr-1" @click="_clipboard(form.secretKey)">mdi-content-copy</v-icon>
          <!-- <v-icon title="Update">mdi-update</v-icon> -->
        </template>
      </v-text-field>

      <v-text-field v-model="survey" label="Survey" readonly>
        <template slot="append">
          <v-icon title="Copy" class="mr-1" @click="_clipboard(survey)">mdi-content-copy</v-icon>
        </template>
      </v-text-field>

      <v-text-field
        v-model="form.name"
        :counter="30"
        :rules="[rules.required]"
        :disabled="isViewer"
        label="Name"
      ></v-text-field>

      <v-text-field
        v-model="form.callback"
        :rules="[rules.url]"
        :disabled="isViewer"
        label="Callback URL"
      ></v-text-field>

      <div class="text-right">
        <v-btn
          color="error darken-1 mr-2"
          @click="_delete()"
          :disabled="!valid"
          v-if="!isViewer"
        >Delete</v-btn>
        <v-btn color="success darken-1" @click="_save()" :disabled="!valid" v-if="!isViewer">Save</v-btn>
        <!-- <div style="display: none;">{{setForm}}</div> -->
      </div>
    </v-form>
    <!-- Confirm -->
    <v-dialog v-model="confirm" max-width="400">
      <v-card>
        <v-card-title class="headline">Do you want to {{type}}?</v-card-title>

        <v-card-text>{{form.name}}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error darken-1" text @click="confirm = false">Cancel</v-btn>

          <v-btn color="green darken-1" text @click="_confirm">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Project from "@/store/models/Project";

export default {
  components: {},
  data: () => {
    return {
      form: {},
      rules: {
        required: (value) => !!value || "Required.",
        url: (value) => {
          if (value) {
            const pattern = /^(https?):\/\/[^\s$.?#].[^\s]*$/;
            return pattern.test(value) || "Invalid URL.";
          } else {
            return true;
          }
        },
      },
      valid: false,
      confirm: false,
      type: null,
    };
  },
  mounted() {
    this.setForm();
  },
  computed: {
    projId() {
      return this.$store.$db().model("projId").find(1);
    },
    isViewer() {
      const projId = this.projId;
      return (
        projId &&
        projId.access &&
        projId.access.role === this.$rolesMember[1].value
      );
    },
    // setForm: {
    //   get() {
    //     const project = Project.find(this.projId && this.projId.value);
    //     console.log(project);
    //     return project;
    //   },
    //   set() {
    //     const project = Project.find(this.projId && this.projId.value);
    //     this.form =
    //       (project && {
    //         id: project.id,
    //         name: project.name,
    //         callback: project.callback,
    //         secretKey: project.secretKey,
    //       }) || {};
    //     return;
    //   }
    // },
    survey() {
      return `${process.env.VUE_APP_SURVEY_URL || ""}/app/${this.projId && this.projId.value}`;
    },
  },
  methods: {
    setForm() {
      const project = Project.find(this.projId && this.projId.value);
      this.form =
        (project && {
          id: project.id,
          name: project.name,
          callback: project.callback,
          secretKey: project.secretKey,
        }) || {};
    },
    _save() {
      this.type = "update";
      this.confirm = true;
    },
    _delete() {
      this.type = "delete";
      this.confirm = true;
    },
    _confirm() {
      if (this.type === "delete") {
        this.delete();
      } else {
        this.update();
      }
    },
    update() {
      if (!this.valid) {
        return;
      }

      const options = {
        data: this.form,
      };

      Project.update(this.form.id, options)
        .then(() => {
          this.confirm = false;
        })
        .catch((err) => {
          this.confirm = false;
          console.error("_save err: ", err);
        });
    },
    delete() {
      Project.api()
        .delete(`/cms/projects/${this.form.id}`, {
          delete: this.form.id,
        })
        .then(() => {
          this.confirm = false;
          this.$router.push({ path: `/` })
        })
        .catch((err) => {
          this.confirm = false;
          console.error("_save err: ", err);
        });
    },
    _clipboard(text) {
      if (text) {
        navigator.clipboard.writeText(text);
      }
    },
  },
  watch: {
    projId: {
      handler() {
        this.setForm();
      },
      deep: false,
    },
  }
};
</script>